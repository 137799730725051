<template>
    <Link rel="icon" href="/images/favicon.ico" />
    <navigation-header v-if="hideNavigation === false" />
    <div id="app" :class="hideNavigation === false ? 'header' : ''">
        <div class="wrapper-user-sidebar">
            <transition name="slide-in-left" appear>
                <user-sidebar
                    v-if="isUserArea && showSidebar"
                    class="user-sidebar"
                />
            </transition>
        </div>
        <NuxtLayout>
            <transition name="page" mode="out-in">
                <div
                    :key="routeKey"
                    :class="['page-wrapper', { 'user-area': isUserArea }]"
                >
                    <NuxtPage :class="['page']" page-key="static" />
                </div>
            </transition>
        </NuxtLayout>
    </div>
    <navigation-footer v-if="hideNavigation === false" />

    <loader id="loader" :run="loading" />

    <simple-layer
        ref="modal"
        v-model:state="modal.state"
        :close-methods="modalOptions.closeMethods"
        :scrollable="modalOptions.scrollable"
        :dialog="modalOptions.dialog"
        :class="modalOptions.class"
        :content-data="modal.data"
        @vue:unmounted="modal.state = false"
    ></simple-layer>
</template>

<script>
import _assign from 'lodash/assign.js'
import useUserStore from '~/stores/user.js'
import useOrderStore from '~/stores/order.js'
import DialogModal from '~/components/modals/Dialog.vue'
const _hsp = (globalThis._hsp = globalThis._hsp || [])

// youtube iframe api
const ytReady = new Promise((resolve, _reject) => {
    if (globalThis.youTubeIframeAPIReady) {
        return resolve()
    }

    globalThis.onYouTubeIframeAPIReady = function () {
        resolve(globalThis.YT)
    }
})

const youTubeIframeAPIRequested = ref(false)

export default {
    provide() {
        return {
            loading: (state) =>
                state !== undefined ? (this.loading = state) : this.loading,
            showModal: (data, options) => this.showModal(data, options),
            showDialog: (title, message, actions, options) =>
                this.showDialog(title, message, actions, options),
            hideModal: () => this.hideModal(),
            isUserArea: this.isUserArea,
            youTubeIframeAPI: (callback) => this.youTubeIframeAPI(callback),
        }
    },

    async setup() {
        const config = useRuntimeConfig()
        const route = useRoute()

        const links = computed(() => [
            {
                rel: 'canonical',
                href: config.public.baseURL + route.path,
            },
        ])

        const scripts = computed(() => [
            process.client && {
                vmid: 'hs-script-loader',
                src: '//js.hs-scripts.com/20349689.js',
                async: true,
                defer: true,
                body: true,
            },
            process.client && youTubeIframeAPIRequested.value
                ? {
                      src: 'https://www.youtube.com/iframe_api',
                      async: true,
                      defer: true,
                  }
                : undefined,
        ])

        const [userStore, orderStore, i18n] = await Promise.all([
            useUserStore(),
            useOrderStore(),
            useI18n(),
            // useAsyncData('authenticated', () => {
            //     const { cookie } = useRequestHeaders(['cookie'])
            //     const userStore = useUserStore()

            //     userStore
            //         .checkAuthentication({ headers: { Cookie: cookie } })
            //         .then((value) => {
            //             console.log('async authenticated:', value)
            //         })
            // }),
            useHead({
                titleTemplate: (title) => {
                    let base = 'recyclehero ♻'

                    if (config.public.env !== 'production') {
                        base += ' (v' + config.public.version + ')'
                    }

                    return title ? base + ' :: ' + title : base
                },
                meta: [
                    { pageTransition: false },
                    { name: 'theme-color', content: '#64A891' },
                    {
                        name: 'description',
                        content:
                            'recyclehero holt recyclebare Wertstoffe in Hamburg ab. Sozial und nachhaltig ✓ So einfach kann Recycling sein – Jetzt ganz einfach abholen lassen!',
                    },
                ],
                link: links,
                script: scripts,
                bodyAttrs: {},
                htmlAttrs: {
                    lang: 'de',
                },
            }),
        ])

        return {
            userStore,
            orderStore,
            i18n,
        }
    },

    data() {
        return {
            modal: {
                state: false,
                data: {},
            },

            modalOptionsDefaults: {
                scrollable: true,
                closeMethods: ['background', 'button'],
                dialog: true,
                class: {},
            },

            modalOptions: {},

            loading: false,
            consent: {
                allowed: undefined,
                categories: {
                    necessary: undefined,
                    functionality: undefined,
                    tracking: undefined,
                    targeting: undefined,
                },
            },

            youTubeIframeAPIRequested: false,

            showSidebar: true,
        }
    },

    computed: {
        isUserArea() {
            return (
                this.$route.name && this.$route.name.substring(0, 5) === 'user-'
            )
        },

        isOrderArea() {
            return (
                this.$route.name && this.$route.name.substring(0, 5) === 'order'
            )
        },

        hideNavigation() {
            return (
                this.$route.name &&
                this.$route.name.substring(0, 9) === 'partners-'
            )
        },

        routeKey() {
            let key = this.$route.path

            if (this.$route.name === 'order') {
                key += '-' + this.$route.query?.tourType
            }
            return key
        },
    },

    watch: {
        'userStore.authenticated'(value) {
            if (!value && this.isUserArea) {
                this.$router.push({ name: 'index' })
            }
        },
        'consent.allowed': {
            handler() {
                // Object.assign(
                //     vm.cookieConsentLevel,
                //     cookieResult.userConsent.acceptedLevels,
                // )

                // setTimeout(() => {
                //     if (
                //         ['tarifvergleich'].indexOf(vm.$route.name) > -1
                //     ) {
                //         vm.loadChatWidget()
                //     }
                // }, 1500)

                setTimeout(() => {
                    this.$tracker.start(this.consent.categories)
                }, 3500)
            },
        },
        // watches URL changes and stores necessary data in the order store
        // https://stackoverflow.com/questions/46402809/vuejs-event-on-route-change
        $route: function (_to, from) {
            let campaignName
            let campaignId

            if (from.query.gclid) {
                campaignName = 'gclid'
                campaignId = from.query.gclid
            } else if (from.query.fbclid) {
                campaignName = 'fbclid'
                campaignId = from.query.fbclid
            }

            this.orderStore.addCampaign(campaignName, campaignId)
        },
    },

    mounted() {
        const vm = this

        _hsp.push([
            'addPrivacyConsentListener',
            function (consent) {
                vm.consent.allowed = consent.allowed

                vm.consent.categories = {
                    tracking: consent.categories.analytics,
                    targeting: consent.categories.advertisement,
                    functionality: consent.categories.functionality,
                    necessary: consent.categories.necessary,
                }
            },
        ])

        if (vm.consent.allowed) {
            setTimeout(() => {
                vm.$tracker.start(vm.consent.categories)
            }, 3500)
        }

        // TODO: use if required
        // window.addEventListener('beforeunload', function (event) {
        //      may reset campaign data
        // })
    },

    methods: {
        youTubeIframeAPI(callback) {
            youTubeIframeAPIRequested.value = true

            ytReady.then(() => {
                callback(window.YT)
            })
        },

        showModal(data, options) {
            const vm = this
            vm.modal.data = markRaw(data)
            vm.modal.state = true
            _assign(vm.modalOptions, vm.modalOptionsDefaults, options)
        },

        hideModal() {
            const vm = this
            if (vm.$refs.modal.internalState === 'hidden' || !vm.modal.state) {
                return Promise.resolve()
            }
            vm.modal.state = false
            return new Promise((resolve) => {
                const stopWatch = vm.$watch(
                    () => vm.$refs.modal.internalState,
                    (value) => {
                        if (value === 'hidden') {
                            stopWatch()
                            vm.modal.data = undefined
                            resolve()
                        }
                    },
                )
            })
        },

        showDialog(title, message, actions = ['close'], options = {}) {
            return new Promise((resolve) => {
                const isList = options.isList
                this.showModal(
                    {
                        component: DialogModal,
                        params: { title, message, actions, isList },
                        afterHide: (reason) => resolve(reason),
                    },
                    options,
                )
            })
        },
    },
}
</script>

<style lang="scss">
#app {
    position: relative;
    z-index: 10;
    width: 100vw;
    min-height: 100vh;
    margin: 0 auto;
    @apply max-w-screen-2xl;
    background-color: #fff;

    &.header {
        padding-top: var(--nav-height);

        &::before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: var(--nav-height);
            background-color: theme('colors.primary-dark');
        }
    }
}

.page {
    background-color: #fff;
    min-height: 100vh;
}

.page-wrapper {
    &.user-area {
        .page {
            padding: var(--section-padding-y) var(--section-padding-x);
        }
    }
}

.header,
.footer {
    z-index: 100;
}

.wrapper-user-sidebar {
    display: none;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: var(--nav-height);
    bottom: 0;
    overflow: hidden;
}

.user-sidebar {
    position: relative;
    height: 100%;
}

section {
    // needed to make functions like scrollIntoView()
    // consider the offset from the header
    scroll-margin-top: var(--nav-height);
}

@screen lg {
    .page-wrapper {
        &.user-area {
            padding-left: var(--user-sidebar-width);

            .page {
                padding: var(--user-area-padding-y) var(--user-area-padding-x);
            }
        }
    }

    .wrapper-user-sidebar {
        display: block;
    }
}
</style>
